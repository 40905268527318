const CheckDevice = () => {
    const isBrowser = typeof window !== "undefined" //check if window is defined

    if(isBrowser){
      let windowWidth = window.innerWidth;
      if (windowWidth < 1025) {
        return true;
      } else {
        return false;
      }
    }
}

export default CheckDevice