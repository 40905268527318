import * as React from 'react'
import Media from '../media'
import PropTypes from 'prop-types'

const GridMedia_3_4 = ({ ...props }) => {
  const mediaArray = [props.media1, props.media2, props.media3];

  return (
    <section className={`container work-detail-module grid_3-4 ${props.flip ? 'flip' : ''}`}>
      {mediaArray.map((media, i) => {
        const image = media.mediaType === 'image' ? media.staticImage.image : media.animatedGif?.image
        const altText = 
          media.mediaType === 'image' 
          ? media.staticImage.altText 
          : media.mediaType === 'gif' 
          ? media.animatedGif?.altText 
          : media.loopingMp4?.altText
      
        return (
          <Media 
            key={i}
            className='media-wrap media-wrap--3-4'
            mediaType={media.mediaType}
            image={image}
            loopingVid={media.loopingMp4?.loopingVid?.asset.fileURL}
            posterImage={media.loopingMp4?.posterImage?.asset.url}
            altText={altText}
            videoOverlay={media.videoOverlay}
            cta={media.cta}
            ctaPlacement={media.CtaPlacement}
            ctaColor={media.CtaColor}
            vimeoID={media.vimeoID}
          />
        )
      })}
    </section>
  )
}

GridMedia_3_4.propTypes = {
  media1: PropTypes.object,
  media2: PropTypes.object,
  media3: PropTypes.object,
}

export default GridMedia_3_4