import * as React from 'react'
import {PortableText} from '@portabletext/react'
import PropTypes from 'prop-types'

const HeadlineCopy = ({ ...props }) => {
  const { headline, copy } = props

  const serializers = {
    marks: {
      link: ({value, children}) => {
        const { blank, href } = value
        return blank 
          ? <a href={href} target="_blank" rel="noreferrer">{children}</a>
          : <a href={href}>{children}</a>
      }
    }
  }

  return (
    <section className='container work-detail-module'>
      {props.headline && (
        <h2 className='headline-copy__headline'>{headline}</h2>
        )}
      <div className='headline-copy__copy'><PortableText value={copy} components={serializers}/></div>
    </section>
  )
}

HeadlineCopy.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.array.isRequired
}

export default HeadlineCopy