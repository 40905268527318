import * as React from 'react'
import {PortableText} from '@portabletext/react'
import Media from './../media'
import PropTypes from 'prop-types'

const MediaOverlapWCopyAlt = ({ ...props }) => {
  const { copy } = props
  const mediaArray = [props.media_1_1, props.media_3_4_a, props.media_3_4_b];

  return (
    <section className={`container work-detail-module media-overlap-alt ${props.flip ? 'flip' : ''}`}>
      {mediaArray.map((media, i) => {
        const image = media.mediaType === 'image' ? media.staticImage.image : media.animatedGif?.image
        const altText = 
          media.mediaType === 'image' 
          ? media.staticImage.altText 
          : media.mediaType === 'gif' 
          ? media.animatedGif?.altText 
          : media.loopingMp4?.altText
      
        return (
          <Media 
            key={i}
            className={`media-wrap ${i === 0 ? 'media-wrap--1-1' : 'media-wrap--3-4'}`}
            mediaType={media.mediaType}
            image={image}
            loopingVid={media.loopingMp4?.loopingVid?.asset.fileURL}
            posterImage={media.loopingMp4?.posterImage?.asset.url}
            altText={altText}
            videoOverlay={media.videoOverlay}
            cta={media.cta}
            ctaPlacement={media.CtaPlacement}
            ctaColor={media.CtaColor}
            vimeoID={media.vimeoID}
          />
        )
      })}
      {copy && (
        <div className='media-overlap-alt__copy'>
          <PortableText value={copy} />
        </div>
      )}

    </section>
  )
}

MediaOverlapWCopyAlt.propTypes = {
  copy: PropTypes.array,
  media_1_1: PropTypes.object,
  media_3_4_a: PropTypes.object,
  media_3_4_b: PropTypes.object,
  flip: PropTypes.bool
}

export default MediaOverlapWCopyAlt