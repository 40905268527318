import * as React from 'react'
import Media from './../media'
import PropTypes from 'prop-types'

const SideBySideMedia_1_1 = ({ ...props }) => {
  const mediaArray = [props.media1, props.media2];

  return (
    <section className="container work-detail-module col2_1-1">
      {mediaArray.map((media, i) => {
        const image = media.mediaType === 'image' ? media.staticImage.image : media.animatedGif?.image
        const altText = 
          media.mediaType === 'image' 
          ? media.staticImage.altText 
          : media.mediaType === 'gif' 
          ? media.animatedGif?.altText 
          : media.loopingMp4?.altText
      
        return (
          <Media 
            key={i}
            className="media-wrap media-wrap--1-1"
            mediaType={media.mediaType}
            image={image}
            loopingVid={media.loopingMp4?.loopingVid?.asset.fileURL}
            posterImage={media.loopingMp4?.posterImage?.asset.url}
            altText={altText}
            videoOverlay={media.videoOverlay}
            cta={media.cta}
            ctaPlacement={media.CtaPlacement}
            ctaColor={media.CtaColor}
            vimeoID={media.vimeoID}
          />
        )
      })}
    </section>
  )
}

SideBySideMedia_1_1.propTypes = {
  media1: PropTypes.object,
  media2: PropTypes.object
}

export default SideBySideMedia_1_1