import * as React from 'react'
import Media from '../media'
import PropTypes from 'prop-types'

const FullWidthMedia = ({ ...props }) => {
  const mediaType = props.media.mediaType;
  const image = props.media.staticImage?.image || props.media.animatedGif?.image 

  const altText = 
    mediaType === 'image' 
    ? props.media.staticImage.altText 
    : mediaType === 'gif' 
    ? props.media.animatedGif.altText 
    : props.media.loopingMp4.altText

  return (
    <section className={`work-detail-module full-width ${props.edgeToEdge === false ? 'container' : ''}`}>
      <Media 
        className='media-wrap media-wrap--auto'
        mediaType={mediaType}
        image={image}
        loopingVid={props.media.loopingMp4?.loopingVid?.asset.fileURL}
        altText={altText}
        videoOverlay={props.media.videoOverlay}
        linkOff={props.media.linkOff}
        linkOffUrl={props.media.linkOffUrl}
        posterImage={props.media?.loopingMp4?.posterImage?.asset.url}
        cta={props.media.cta}
        ctaPlacement={props.media?.CtaPlacement}
        ctaColor={props.media.CtaColor}
        vimeoID={props.media.vimeoID}
      />
    </section>
  )
}

FullWidthMedia.propTypes = {
  media: PropTypes.object.isRequired,
  edgeToEdge: PropTypes.bool
}

export default FullWidthMedia