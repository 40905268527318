import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeadlineCopy from "../components/workDetail/headlineCopy"
import Blurb from "../components/workDetail/blurb"
import CopyBlock from "../components/workDetail/copyBlock"
import FullWidthMedia from "../components/workDetail/fullWidthMedia"
import GridMedia_3_4 from "../components/workDetail/gridMedia_3_4"
import MediaOverlapWCopy from "../components/workDetail/mediaOverlapWCopy"
import MediaOverlapWCopyAlt from "../components/workDetail/mediaOverlapWCopyAlt"
import MediaOverlapWCopyAlt2 from "../components/workDetail/mediaOverlapWCopyAlt2"
import SideBySideMedia_1_1 from "../components/workDetail/sideBySideMedia_1_1"
import SideBySideMedia_3_4 from "../components/workDetail/sideBySideMedia_3_4"
import SideBySideMedia_9_16 from "../components/workDetail/sideBySideMedia_9_16"
import Credentials from "../components/workDetail/credentials"

const WorkDetail = ({ location, data }) => {
  const workItem = data.allSanityWorkItem.edges[0].node

  const content = (workItem._rawContent || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "blurb":
          el = <Blurb key={c._key} {...c} />
          break
        case "copyBlock":
          el = <CopyBlock key={c._key} {...c} />
          break
        case "fullWidthMedia_16_9":
          el = <FullWidthMedia key={c._key} {...c} />
          break
        case "gridMedia_3_4":
          el = <GridMedia_3_4 key={c._key} {...c} />
          break
        case "headlineCopy":
          el = <HeadlineCopy key={c._key} {...c} />
          break
        case "mediaOverlapWCopy":
          el = <MediaOverlapWCopy key={c._key} {...c} />
          break
        case "mediaOverlapWCopyAlt":
          el = <MediaOverlapWCopyAlt key={c._key} {...c} />
          break
        case "mediaOverlapWCopyAlt2":
          el = <MediaOverlapWCopyAlt2 key={c._key} {...c} />
          break
        case "sideBySideMedia_1_1":
          el = <SideBySideMedia_1_1 key={c._key} {...c} />
          break
        case "sideBySideMedia_3_4":
          el = <SideBySideMedia_3_4 key={c._key} {...c} />
          break
        case "sideBySideMedia_9_16":
          el = <SideBySideMedia_9_16 key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <Layout scheme="light" location={location} pageCategory="work">
      <Seo title={`${workItem.client.name} — ${workItem.title}`} />

      <div className="container header-spacer">
        <h1 className="work-detail__header">
          {workItem.client.name}{" "}
          <span className="work-detail__title">{workItem.title}</span>
        </h1>
      </div>

      <div>{content}</div>

    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    
    allSanityWorkItem(
      filter: {id: { eq: $id }, workType: {nin: "work-ml"}}
      ) { 
      edges {
        node {
          title
          client {
            name
          }
          offices {
            id
            city
          }
          capabilities {
            name
            order
            id
          }
          industry
          _rawRecognitionRich
          _rawContent(resolveReferences: { maxDepth: 30 })
        }
      }
    }
  }
`

export default WorkDetail
