import * as React from "react"
import { useState, useEffect} from "react"
import useToggleState from "./../hooks/useToggleState"
import CheckDevice from './checkMobile'
import PropTypes from "prop-types"
import SanityImage from "gatsby-plugin-sanity-image"
import { Dialog } from "@reach/dialog"
import VideoOverlay from "./videoOverlay"
import VideoOverlayCta from "./workDetail/videoOverlayCta"
import LinkOffCta from "./workDetail/linkOffCta"
import "@reach/dialog/styles.css"


const Media = ({ ...props }) => {
  const [overlayOpen, toggle] = useToggleState(false);
  const [counter, setCounter] = useState(1);
  const [handleClickCheck, setHandleClickCheck] = useState(false);
  const [isMobile, setIsMobile] = useState(CheckDevice);

  const handleClick = () => toggle();

  const handleClickChecker = (e) => {
    setCounter(counter + 1);
    if(counter <= 1){
      e.preventDefault();
      setHandleClickCheck(true);
    }
  }

  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(CheckDevice);
    };

    window.addEventListener('resize', handlePageResized);
    return () => {
      window.removeEventListener('resize', handlePageResized);
    };
  },[isMobile]);

 

  return (
    <>
      {(props.mediaType === 'image' || props.mediaType === 'gif') && (
        <div className={`${props.className}`} onClick={handleClickChecker} onKeyDown={handleClickChecker} role="button" tabIndex="0">
          <SanityImage {...props.image} alt={props.altText || ''} />

          {props.videoOverlay && (
            <VideoOverlayCta 
              ctaPlacement={props.ctaPlacement}
              ctaColor={props.ctaColor}
              handleClick={handleClick}
            > 
              {props.cta}
            </VideoOverlayCta>
          )}

          {props.linkOff && (
            <LinkOffCta 
              ctaPlacement={props.ctaPlacement}
              ctaColor={props.ctaColor}
              url={props.linkOffUrl} > 
              {props.cta} 
            </LinkOffCta>
          )}

          {isMobile && handleClickCheck && <h2 className="mobile-work-title">{props.title}</h2>}
        </div>
      )}

      {props.mediaType === 'mp4' && (
        <div className={`${props.className} looping-vid__wrap`}>
          <video className="looping-vid" poster={props.posterImage} autoPlay loop playsInline muted>
            <source src={props.loopingVid}/>
          </video>

          {props.videoOverlay && (
            <VideoOverlayCta 
              ctaPlacement={props.ctaPlacement}
              ctaColor={props.ctaColor}
              handleClick={handleClick}
            > 
              {props.cta}
            </VideoOverlayCta>
          )}

          {props.linkOff && (
            <LinkOffCta 
              ctaPlacement={props.ctaPlacement}
              ctaColor={props.ctaColor}
              url={props.linkOffUrl} > 
              {props.cta} 
            </LinkOffCta>
          )}
        </div>
      )}

      {props.videoOverlay && (
        <Dialog isOpen={overlayOpen} onDismiss={handleClick} aria-label="overlay">
          <VideoOverlay handleClose={handleClick} vimeoID={props.vimeoID}/>   
        </Dialog>
      )}
    </>
  )
}


export default Media

Media.propTypes = {
  mediaType: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.object,
  altText: PropTypes.string,
  cta: PropTypes.string,
  vimeoID: PropTypes.string,
  videoOverlay: PropTypes.bool,
  posterImage: PropTypes.string,
  title: PropTypes.string
}