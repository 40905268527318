import * as React from 'react'
import {PortableText} from '@portabletext/react'
import PropTypes from "prop-types"

const Blurb = ({ ...props }) => {
  const { copy } = props

  return (
    <section className={`blurb container work-detail-module ${props.paddingTop ? 'blurb__paddingTop' : ''}`}>
      <PortableText value={copy} />
    </section>
  )
}

Blurb.propTypes = {
  copy: PropTypes.array.isRequired
}

export default Blurb