import * as React from 'react'
import classNames from "classnames"
import PropTypes from 'prop-types'

const LinkOffCta = props => (
  <a 
    href={props.url} 
    className={classNames('media__cta', props.ctaPlacement, props.ctaColor)} 
    target="_blank" rel="noreferrer"
  >
    {props.children}
  </a>
)

LinkOffCta.propTypes = {
  url: PropTypes.string.isRequired,
  ctaPlacement: PropTypes.string,
  ctaColor: PropTypes.string
}

export default LinkOffCta