import React from "react"
import useLockBodyScroll from './../hooks/useLockBodyScroll';
import Vimeo from '@u-wave/react-vimeo';
import PropTypes from 'prop-types'

const VideoOverlay = ({ handleClose, vimeoID }) => {
  useLockBodyScroll();
  
  return (
    <>
      <div className="vid-overlay" 
        onClick={handleClose}
        onKeyPress={handleClose}
        role="button"
        tabIndex={0}
      > 
      
        <div className="close__wrap">
          close
        </div>
        
        <div className="vid-overlay__vid-centering-wrap">
          <div className="vid-overlay__vid-wrap">
            {vimeoID && (
              <div className="vid-overlay__vid">
                <Vimeo 
                  video={vimeoID} 
                  className="vid-overlay__iframe"
                  textTrack="en-x-autogenerated"
                  onEnd={() => handleClose()}
                />
              </div>
            )}
          </div>
        </div>

      </div>
    </>
  )
}

VideoOverlay.propTypes = {
  handleClose: PropTypes.func.isRequired,
  vimeoID: PropTypes.string.isRequired
}

export default VideoOverlay