import * as React from 'react'
import classNames from "classnames"
import PropTypes from "prop-types"

const VideoOverlayCta = ({ ...props }) => (
  <button 
    className={classNames('media__cta', props.ctaPlacement, props.ctaColor)}
    onClick={() => props.handleClick()} 
    onKeyPress={() => props.handleClick()}
  >
    {props.children}
  </button>
)

VideoOverlayCta.propTypes = {
  children: PropTypes.node.isRequired,
  ctaPlacement: PropTypes.string,
  ctaColor: PropTypes.string
}

export default VideoOverlayCta